/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import HEADSHOT from "@/assets/images/headshot.jpeg";

const Seo = ({
  description = "A result-oriented software engineer with experience in product planning, analysis, design and development in both web and mobile platforms. Working as a full-stack developer specialized in node.js development environment for over five years. Over this period of time, worked in multiple enterprise-level solutions and mobile applications.",
  lang = "en",
  meta,
  title = "S. M. Foisal",
}: any) => (
  <Helmet htmlAttributes={{ lang }}>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta name="description" content={description} />

    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://smfoisal.com/" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={HEADSHOT} />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://smfoisal.com/" />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={HEADSHOT} />
  </Helmet>
);
export default Seo;
