import React from "react";
import Layout from "@/components/layout";
import styled from "styled-components";
import { LogoGithub, LogoLinkedin } from "react-ionicons";
import { motion } from "framer-motion";

const IndexPage = () => {
  return (
    <Layout title={"Welcome to S. M. Foisal"}>
      <Container>
        <h2>Hello,</h2>
        <h1>I'm S. M. Foisal</h1>
        <h2>A software engineer</h2>
        <Flex>
          <motion.a
            href={"https://github.com/smfoisal"}
            target={"_blank"}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <LogoGithub
              color={"white"}
              height={"64px"}
              width={"64px"}
              style={{ marginRight: 32 }}
            />
          </motion.a>
          <motion.a
            href={"https://www.linkedin.com/in/smfoisal/"}
            target={"_blank"}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <LogoLinkedin color={"white"} height={"64px"} width={"64px"} />
          </motion.a>
        </Flex>
        <Footer>This site is still under-construction</Footer>
      </Container>
    </Layout>
  );
};

const Container = styled.div(() => ({
  maxWidth: 960,
  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  "flex-direction": "column",
  minHeight: "100vh",
  justifyContent: "center",
}));

const Flex = styled.div(() => ({
  display: "flex",
  "flex-direction": "row",
  marginTop: 72,
}));

const Footer = styled.div(() => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  paddingBottom: 24,
  textAlign: "center",
  opacity: "20%",
  fontSize: 12,
  textTransform: "uppercase",
}));

export default IndexPage;
