import { createGlobalStyle } from "styled-components";
import { colors } from "@/config/theme";
import FiraMonoWoff from "@/assets/fonts/fira-mono/FiraMono-Medium.woff";
import FiraMonoWoff2 from "@/assets/fonts/fira-mono/FiraMono-Medium.woff2";

const defaultFonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Helvetica",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
];

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Fira Mono';
    src: local('Fira Mono'), local('Fira Mono'),
    url(${FiraMonoWoff2}) format('woff2'),
    url(${FiraMonoWoff}) format('woff');
    font-weight: 1 999;
  }

  html,
  body {
    min-height: 100vh;
  }

  body {
    color: white;
    font-family: 'Fira Mono', ${defaultFonts.join(",")};
    font-smooth: antialiased;
    font-weight: 999;
    font-size: 16px;
    padding:0;
    margin:0;
    background-color: ${colors.primaryBase};
  }

  h1 {
    font-size: 64px;
    line-height: 1.2;
    margin: 0;
  }

  svg {
    transition: 0.3s ease-in-out;

    &:hover {
      fill: #17E6B7;
    }
  }
`;

export default GlobalStyle;
