import React, { ReactNode } from "react";
import GlobalStyle from "@/styles/global";
import Seo from "./seo";
interface Props {
  location?: string | any;
  title?: string;
  children: ReactNode;
}

const Layout = ({ location, title, children }: Props) => (
  <>
    <Seo title={title} />
    <GlobalStyle />
    <main>{children}</main>
  </>
);

export default Layout;
